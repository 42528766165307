/*
 * Copyright 2017-2020 The ShadowEditor Authors. All rights reserved.
 *
 * Use of this source code is governed by a MIT-style
 * license that can be found in the LICENSE file.
 *
 * For more information, please visit: https://github.com/tengge1/ShadowEditor
 * You can also visit: https://gitee.com/tengge1/ShadowEditor
 */
//This is needed by old build system
import "./polyfills";
import "./render/shader/ShaderChunk";

import global from "./global";

import Application from "./Application";

const app = new Application(document.getElementById("container"), {
  server: location.origin,
  enableCache: true,
});
global.app = app;
